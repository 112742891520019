import React from 'react';

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

export function Section({ title, children }: SectionProps) {
  return (
    <section className="mb-4 backdrop-blur-sm bg-white/50 p-6 rounded-xl shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] transition-all duration-300 hover:shadow-[0_20px_60px_rgba(8,_112,_184,_0.8)]">
      <h2 className="text-2xl font-bold mb-4 text-center bg-gradient-to-r from-[#1A8F86] to-[#B08E2A] bg-clip-text text-transparent">{title}</h2>
      <div>
        {children}
      </div>
    </section>
  );
}