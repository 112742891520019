import React from 'react';
import { BookText } from 'lucide-react';

export function PublicationsButton() {
  return (
    <a
      href="https://www.amazon.com/dp/B0CQ89FCG1?binding=paperback&ref=dbs_dp_sirpi"
      target="_blank"
      rel="noopener noreferrer"
      className="block w-full bg-[#C0C0C0] text-black border border-black rounded-lg px-4 py-3 text-center font-medium hover:scale-[1.02] transition-all duration-300 hover:shadow-lg flex items-center justify-center gap-3"
    >
      <BookText size={24} />
      <span>Publications</span>
    </a>
  );
}