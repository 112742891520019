import React from 'react';
import { Link } from 'react-router-dom';
import { 
  BookOpen, 
  BrainCircuit,
  Sparkles, 
  Users, 
  Crown, 
  Building2,
  Bot,
  Video,
  FileCheck,
  Lightbulb
} from 'lucide-react';
import { Header } from '../components/Header';
import { Section } from '../components/Section';
import { SocialLinks } from '../components/SocialLinks';
import { AboutButton } from '../components/AboutButton';
import { PublicationsButton } from '../components/PublicationsButton';
import { PageLayout } from '../components/layout/PageLayout';

export function Home() {
  return (
    <PageLayout showBackButton={false}>
      <Header />
      <div className="space-y-1">
        <AboutButton />
        <PublicationsButton />
        <Link to="/essentials" className="block">
          <div className="flex items-center justify-center gap-3 w-full px-4 py-3 rounded-lg transition-all duration-300 hover:scale-[1.02] font-medium backdrop-blur-sm shadow-[0_8px_30px_rgb(0,0,0,0.12)] hover:shadow-[0_8px_30px_rgba(0,0,0,0.25)] bg-gradient-to-r from-accent-turquoise to-accent-gold text-white">
            <Sparkles size={24} />
            <span>Discover Books and AI Solutions</span>
          </div>
        </Link>
      </div>
      
      <main className="space-y-6 mt-6">
        <Section title="Books">
          <div className="space-y-2">
            <Link to="/essentials" className="block">
              <div className="flex items-center justify-center gap-3 w-full px-4 py-3 rounded-lg transition-all duration-300 hover:scale-[1.02] font-medium backdrop-blur-sm shadow-[0_8px_30px_rgb(0,0,0,0.12)] hover:shadow-[0_8px_30px_rgba(0,0,0,0.25)] bg-primary-light/90 text-primary-dark border border-primary-dark hover:bg-primary-light">
                <BookOpen size={24} />
                <span>Start Writing with Expert Tools</span>
              </div>
            </Link>
            <Link to="/collaborative" className="block">
              <div className="flex items-center justify-center gap-3 w-full px-4 py-3 rounded-lg transition-all duration-300 hover:scale-[1.02] font-medium backdrop-blur-sm shadow-[0_8px_30px_rgb(0,0,0,0.12)] hover:shadow-[0_8px_30px_rgba(0,0,0,0.25)] bg-primary-light/90 text-primary-dark border border-primary-dark hover:bg-primary-light">
                <Users size={24} />
                <span>Co-Create Your Book with Experts</span>
              </div>
            </Link>
            <Link to="/premium" className="block">
              <div className="flex items-center justify-center gap-3 w-full px-4 py-3 rounded-lg transition-all duration-300 hover:scale-[1.02] font-medium backdrop-blur-sm shadow-[0_8px_30px_rgb(0,0,0,0.12)] hover:shadow-[0_8px_30px_rgba(0,0,0,0.25)] bg-gradient-to-r from-accent-turquoise to-accent-gold text-white">
                <Crown size={24} />
                <span>Get Your Book Done for You</span>
              </div>
            </Link>
            <Link to="/publishing" className="block">
              <div className="flex items-center justify-center gap-3 w-full px-4 py-3 rounded-lg transition-all duration-300 hover:scale-[1.02] font-medium backdrop-blur-sm shadow-[0_8px_30px_rgb(0,0,0,0.12)] hover:shadow-[0_8px_30px_rgba(0,0,0,0.25)] bg-accent-cream/90 text-primary-dark border border-primary-dark hover:bg-accent-cream">
                <Building2 size={24} />
                <span>Get Your Publishing Needs Met</span>
              </div>
            </Link>
          </div>
        </Section>

        <Section title="Bots">
          <div className="space-y-2">
            <Link to="/custom-gpts" className="block">
              <div className="flex items-center justify-center gap-3 w-full px-4 py-3 rounded-lg transition-all duration-300 hover:scale-[1.02] font-medium backdrop-blur-sm shadow-[0_8px_30px_rgb(0,0,0,0.12)] hover:shadow-[0_8px_30px_rgba(0,0,0,0.25)] bg-primary-light/90 text-primary-dark border border-primary-dark hover:bg-primary-light">
                <BrainCircuit size={24} />
                <span>Custom Made Bots</span>
              </div>
            </Link>
            <Link to="/gpt-shop" className="block">
              <div className="flex items-center justify-center gap-3 w-full px-4 py-3 rounded-lg transition-all duration-300 hover:scale-[1.02] font-medium backdrop-blur-sm shadow-[0_8px_30px_rgb(0,0,0,0.12)] hover:shadow-[0_8px_30px_rgba(0,0,0,0.25)] bg-primary-light/90 text-primary-dark border border-primary-dark hover:bg-primary-light">
                <Bot size={24} />
                <span>Explore our Bot Membership</span>
              </div>
            </Link>
          </div>
        </Section>

        <Section title="Free Tools">
          <div className="space-y-2">
            <Link to="/webinar" className="block">
              <div className="flex items-center justify-center gap-3 w-full px-4 py-3 rounded-lg transition-all duration-300 hover:scale-[1.02] font-medium backdrop-blur-sm shadow-[0_8px_30px_rgb(0,0,0,0.12)] hover:shadow-[0_8px_30px_rgba(0,0,0,0.25)] bg-accent-cream/90 text-primary-dark border border-primary-dark hover:bg-accent-cream">
                <Video size={24} />
                <span>Learn How to Leverage AI in Your Business</span>
              </div>
            </Link>
            <Link to="/checklist" className="block">
              <div className="flex items-center justify-center gap-3 w-full px-4 py-3 rounded-lg transition-all duration-300 hover:scale-[1.02] font-medium backdrop-blur-sm shadow-[0_8px_30px_rgb(0,0,0,0.12)] hover:shadow-[0_8px_30px_rgba(0,0,0,0.25)] bg-accent-cream/90 text-primary-dark border border-primary-dark hover:bg-accent-cream">
                <FileCheck size={24} />
                <span>Download Your Free Publishing Checklist</span>
              </div>
            </Link>
            <Link to="/free-gpt" className="block">
              <div className="flex items-center justify-center gap-3 w-full px-4 py-3 rounded-lg transition-all duration-300 hover:scale-[1.02] font-medium backdrop-blur-sm shadow-[0_8px_30px_rgb(0,0,0,0.12)] hover:shadow-[0_8px_30px_rgba(0,0,0,0.25)] bg-accent-cream/90 text-primary-dark border border-primary-dark hover:bg-accent-cream">
                <Lightbulb size={24} />
                <span>Free GPT from Jenn</span>
              </div>
            </Link>
          </div>
        </Section>

        <Section title="Social">
          <SocialLinks />
        </Section>
      </main>
    </PageLayout>
  );
}